.invoice {
    &Card {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100vh;
        &Container {
            height: 100vh;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
    &Content {
        width: 402px;
        height: 602px;
        background-color: #0d0d0d;
        border-radius: 20px;
        margin: 50px 0 0 0;
        overflow: hidden;
        border: 1px solid #333;
    }
    &Header {
        padding: 20px;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 225px;
        width: 100%;
        gap: 50px;

        &Info {
            z-index: 1;

            &Item {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
            &Network {
                font-size: 13px;
                font-weight: 400;
            }
            &Sum {
                font-size: 36px;
                font-weight: 700;
                line-height: 43px;
            }
            &Commission {
                font-size: 14px;
                line-height: 17px;
                color: #666;
            }
        }
    }
    &Banner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &TitleContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
    }
    &Title {
        font-size: 16px;
        line-height: 39px;
        font-weight: bold;
        margin: 0;
    }
    &Body {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 400px;
        height: 306px;

        &Container {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 10px;
        }
        &Item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 175px;
            height: 70px;
            background-color: #1a1a1a;
            border-radius: 20px;
            padding: 10px;

            &Title {
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                color: #666;
            }
            &Subtitle {
                font-size: 18px;
                line-height: 22px;
                font-weight: 700;
            }
        }
        &TimerInfo {
            display: flex;
            flex-direction: column;
            height: 44px;
            gap: 5px;
        }
        &Way {
            display: flex;
            width: 360px;
            height: 110px;
            gap: 10px;
            align-items: center;
            &Qr {
                min-width: 110px;
                min-height: 110px;
                background-color: white;
                border-radius: 5px;
                padding: 2px;
            }
            &Wrapper {
                display: flex;
                flex-direction: column;
                height: 80px;
                gap: 5px;
            }
            &AddressTitle {
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                color: #666;
            }
            &Address {
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;
                word-wrap: break-word;
                word-break: break-all;
            }
        }
    }
    &Footer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px 20px 20px;
        height: 69px;
        &Link {
            cursor: pointer;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            color: #666;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.loading {
    font-size: 24px;
    font-weight: bold;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.circularProgressContainer {
    width: 50px;
    height: 50px;
}

.invoiceUuid {
    width: max-content;
    height: max-content;
    font-size: 14px;
    color: #ffffff;
    margin-top: 5px;
}

.invoiceBodyWayQr {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
}
