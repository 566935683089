.mainSupport {
    display: flex;
    flex-direction: column;
    margin: 3px 32px 0 32px;
    border-radius: 10px;
    padding: 20px 0 0 0;
    background-color: light-dark(var(--color-light), var(--color-dark));
    overflow: hidden;
    &Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px 20px 20px;
    }
    &Title {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Button {
        display: inline-flex;
        gap: 10px;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #fff;
        padding: 10px 24px;
        border-radius: 8px;
        background-color: #0062ff;
        transition: transform 0.1s ease;
        &:active {
            transform: scale(0.95);
        }
    }
    &Table {
        width: 100%;
        border-collapse: collapse;
        &Title {
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #8b8b8b;
            padding: 8px 24px;
            border: solid light-dark(var(--info-border-color-light), var(--info-border-color-dark));
            border-width: 1px 0 1px 0;
            background-color: light-dark(var(--info-background-color-light), var(--info-background-color-dark));
        }
        &Body {
            min-height: 150px;
            max-height: 70vh;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                background-color: light-dark(var(--second-color-light), var(--second-color-dark));
                border-radius: 15px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #282c38;
                border-radius: 15px;
            }
            &::-webkit-scrollbar-corner {
                background-color: light-dark(var(--color-light), var(--color-dark));
            }
        }
    }
    &TextCenter {
        text-align: center;
    }
    &Tr {
        cursor: pointer;
        border-collapse: separate;
        border-bottom: 1px solid light-dark(var(--info-border-color-light), var(--info-border-color-dark));
        transition: background-color 0.2s ease;
        height: 50px;
        &:hover {
            background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        }
        &Text {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
        }
        &Id {
            font-weight: 600;
        }
        &Status {
            font-size: 12px;
            line-height: 14px;
            padding: 10px;
            border-radius: 100px;
            &InProgress {
                background-color: #3a2b1f;
                color: #ffb800;
            }
            &Freeze {
                background-color: light-dark(
                    var(--negative-background-color-light),
                    var(--negative-background-color-dark)
                );
                color: light-dark(var(--negative-text-color-light), var(--negative-text-color-dark));
            }
            &Closed {
                background-color: light-dark(
                    var(--positive-background-color-light),
                    var(--positive-background-color-dark)
                );
                color: light-dark(var(--positive-text-color-light), var(--positive-text-color-dark));
            }
        }
        &Actions > a {
            color: #0062ff;
        }
    }
}
.dialog {
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    &::backdrop {
        background-color: light-dark(var(--backdrop-color-light), var(--backdrop-color-dark));
        backdrop-filter: blur(5px);
    }
    &Container {
        display: flex;
        flex-direction: column;
        padding: 40px;
        width: 550px;
    }
    &Header {
        display: flex;
        justify-content: space-between;
        padding: 0 0 50px 0;
    }
    &Title {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
    }
    &Content {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    &Label {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    &Span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Input {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding: 17px 14px;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        border-radius: 10px;
    }
    &Button {
        width: 100%;
        padding: 11px 0;
        border-radius: 10px;
        background-color: #0062ff;
        transition: transform 0.1s ease;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #fff;
        &:active {
            transform: scale(0.95);
        }
    }
    &Description {
        width: 100%;
        height: 150px;
        resize: none;
        border: 1px solid light-dark(var(--info-border-color-light), var(--info-border-color-dark));
        border-radius: 10px;
        padding: 10px;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-thumb:hover {
            cursor: auto;
        }
        &::-webkit-scrollbar-track {
            background-color: initial;
            border-radius: 15px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #282c38;
            border-radius: 15px;
        }
    }

    &Upload {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 10px;
    }
    &Attachment {
        cursor: pointer;
        display: flex;
        gap: 5px;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #0062ff;
        &Svg {
            width: 16px;
            height: 16px;
        }
    }

    &AttachmentInput {
        display: none;
    }

    &Footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}
#fileName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
