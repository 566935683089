@media (max-width: 1600px) {
    .transactions {
        &Block {
            position: relative;
            overflow-x: scroll;
            &::-webkit-scrollbar {
                height: 5px;
            }
            &::-webkit-scrollbar-track {
                background-color: light-dark(var(--color-light), var(--color-dark));
                border-radius: 15px;
                width: 100%;
                height: 100%;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #282c38;
                border-radius: 15px;
            }
        }
    }
    .transactionsLine,
    .list {
        min-width: 1310px;
    }
}
@media (max-width: 1250px) {
    .transactions {
        padding: 0 25px;
    }
}

.transactions {
    &Dialog {
        @media (max-width: 768px) {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            padding: 0;
            width: 100vw;
            height: 100vh;
            max-width: none;
            max-height: none;
            border: none;
            border-radius: 0;

            &Block {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
            }
            &Container {
                width: 100%;
                height: max-content;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &Header {
                padding: 20px;
            }

            &Ul {
                gap: 15px;
            }

            &Label {
                gap: 10px;
            }

            &Checkbox {
                min-width: 20px;
                min-height: 20px;
            }

            &Buttons {
                padding: 20px;
                gap: 15px;
            }

            &Close,
            &Save {
                padding: 12px 20px;
            }
        }

        @media (max-width: 480px) {
            &Header {
                padding: 15px;
            }

            &Wrapper {
                padding: 15px;
                gap: 15px;
            }

            &Ul {
                gap: 10px;
            }

            &Label {
                gap: 8px;
            }

            &Checkbox {
                min-width: 18px;
                min-height: 18px;
            }

            &Buttons {
                padding: 15px;
                gap: 10px;
            }

            &Close,
            &Save {
                padding: 10px 15px;
            }
        }
    }
}
