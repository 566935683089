.topups {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    padding: 3px 32px 0 32px;
    &Item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 100%;
        width: 100%;
        min-width: 0;
        min-height: 110px;
        padding: 20px;
        gap: 10px;
        background-color: light-dark(var(--color-light), var(--color-dark));
        border-radius: 10px;
    }
    &Title {
        font-weight: 400;
        font-size: 15px;
        color: light-dark(var(--text-color-light), var(--text-color-dark));
        line-height: 140%;
    }
    &Info {
        display: flex;
        align-items: end;
        justify-content: space-between;
    }
    &Sum {
        font-weight: 400;
        font-size: 24.5px;
        color: light-dark(var(--text-color-light), var(--text-color-dark));
    }
    &Percent {
        display: inline-flex;
        justify-content: center;
        font-weight: 400;
        font-size: 12px;
        width: max-content;
        height: max-content;
        padding: 3px 8px;
        border-radius: 14px;
    }
}
.positive {
    color: light-dark(var(--positive-text-color-light), var(--positive-text-color-dark));
    background-color: light-dark(var(--positive-background-color-light), var(--positive-background-color-dark));
}
.negative {
    color: light-dark(var(--negative-text-color-light), var(--negative-text-color-dark));
    background-color: light-dark(var(--negative-background-color-light), var(--negative-background-color-dark));
}

.topupsInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Специальные стили для карточки all_time */
.alltime .topupsInfo {
    justify-content: center; /* Центрируем сумму */
}

.topupsSum {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.006em;
}
