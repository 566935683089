.subscriptions {
    display: flex;
    flex-direction: column;
    gap: 25px;
    &WrapperTitle {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 13px;
    }
    &TitleTitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
    }
    &TitlePeriod {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        padding: 6px 16px;
        border: 1px solid #0062ff;
        transition: transform 0.1s ease;
        &:active {
            transform: scale(0.95);
        }
    }
    &TitlePeriodActive {
        background-color: #0062ff;
        color: #fff;
    }
    &WrapperMain {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 16px;
    }
    &Item {
        display: flex;
        flex-direction: column;
        padding: 40px 30px;
        background-color: light-dark(var(--color-light), var(--color-dark));
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        border-radius: 10px;
        height: 335px;
        cursor: pointer;
        transition: transform 0.1s ease;
        &:active {
            transform: scale(0.95);
        }
    }
    &ItemActive {
        padding: 39px 29px;
        border: 2px solid #0062ff;
    }
    &Info {
        display: flex;
        flex-direction: column;
        gap: 14px;
        max-width: 220px;
    }
    &InfoTitle,
    &InfoTitle2,
    &InfoTitle3 {
        display: inline-flex;
        align-items: center;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
    }
    &InfoTitle2 {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px;
        font-weight: 700;
        color: #ff9400;
    }
    &InfoTitle3 {
        font-weight: 700;
        color: #0062ff;
    }
    &InfoTitleSpan {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 4px;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        color: #fff;
        background-color: #ff9400;
        padding: 5px 12px;
        border-radius: 5px;
    }

    &InfoText,
    &InfoPrice {
        display: inline-flex;
        align-items: center;
        gap: 7px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }
    &Span {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        max-width: 140px;
    }
    &Advantages {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: auto;
        margin-left: auto;
    }
    &Advantage {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }

    &Title {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
    }
    &WrapperPayment {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 16px;
    }
    &PaymentItem {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 20px;
        padding: 18px 30px;
        background-color: light-dark(var(--color-light), var(--color-dark));
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        border-radius: 10px;
        cursor: pointer;
        transition: transform 0.1s ease;
        &:active {
            transform: scale(0.95);
        }
    }
    &PaymentItemActive {
        padding: 17px 29px;
        border: 2px solid #0062ff;
    }
    &PaymentText {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 14px;
    }
    &PaymentTitle {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
    }
    &PaymentSubtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }
    &Svg {
        width: 46px;
        height: 40px;
    }
    &Button {
        padding: 10px 24px;
        border-radius: 8px;
        width: max-content;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        background-color: #0062ff;
        color: #fff;
    }
}
