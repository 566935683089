.aside {
    position: fixed;
    width: 240px;
    background-color: light-dark(var(--color-light), var(--color-dark));
    overflow: hidden;
    gap: 5px;
    z-index: 1;
    transition: left 0.3s ease-in-out;
    margin: 20px 0 20px 15px;
    border-radius: 10px;
    height: calc(100vh - 40px);
    & > div {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: light-dark(var(--color-light), var(--color-dark));
        border-radius: 15px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #282c38;
        border-radius: 15px;
    }
}
.titleWrapper {
    display: flex;
    padding: 25px 0 25px 40px;
}
.logo {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 150px;
}
.logoTitle {
    display: inline;
    font-weight: 500;
    font-size: 14.51px;
    background-image: var(--title-text-color);
    -webkit-background-clip: text;
    background-clip: text;
    color: light-dark(var(--title-color-light), var(--title-color-dark));
    margin: 0 0 0 6px;
}
.item {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 0 0 15px 20px;
    &:not(:last-child) {
        border-bottom: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
    }
    &:not(:nth-child(2)) {
        margin: 20px 0 0 0;
    }
}
.title {
    font-weight: 400;
    font-size: 12px;
    color: #8b8b8b;
    margin: 0 0 10px 20px;
    text-transform: uppercase;
}
.profile {
    display: flex;
    align-items: center;
    max-width: 100%;
    width: 171px;
    height: 42px;
    background-color: light-dark(var(--second-color-light), var(--second-color-dark));
    border-radius: 10px;
    transition:
        width 0.4s ease-in-out,
        border-radius 0.7s ease-in-out;
}
.activeProfile {
    width: 100%;
    border-radius: 10px 0 0 10px;
    border-right: 2px solid #0062ff;
}
.avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0 8px 0 10px;
}
.email {
    font-weight: 400;
    font-size: 12px;
    color: light-dark(var(--text-color-light), var(--text-color-dark));
    padding: 0 10px 0 0;
}
.navButton {
    display: flex;
    align-items: center;
    background-color: transparent;
    width: 100%;
    padding: 13px 0;
    border-radius: 10px 0 0 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    &:hover {
        background-color: light-dark(var(--second-color-light), var(--second-color-dark));
    }
}
.active {
    background-color: light-dark(var(--second-color-light), var(--second-color-dark));
    border-right: 2px solid #0062ff;
    & > .link > .svg {
        fill: light-dark(var(--text-color-light), var(--text-color-dark));
    }
    & > .link {
        color: light-dark(var(--text-color-light), var(--text-color-dark));
    }
}
.link {
    font-weight: 400;
    font-size: 14px;
    color: #8b8b8b;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;
}
.svg {
    width: 15px;
    height: 15px;
    margin: 0 10px 0 20px;
    transition:
        fill 0.2s ease,
        stroke 0.2s ease;
}

.offer {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    &Wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        max-width: 220px;
        min-height: 90px;
        border-radius: 6px;
        padding: 10px 12px;
        background: var(--offer-background);
    }
    &Title {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        line-height: 15px;
        gap: 8px;
    }
    &TitleSvg {
        min-width: 23px;
        min-height: 23px;
    }
    &TitleText {
        font-weight: 400;
        font-size: 12px;
        color: light-dark(var(--text-color-light), var(--text-color-dark));
    }
    &Button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 26px;
        background: transparent;
        border: 1px solid #474747;
        border-radius: 6px;
        transition:
            background-color 0.2s ease,
            color 0.2s ease;
        &:hover {
            background-color: light-dark(var(--text-color-light), var(--text-color-dark));
            border: #fff;
        }
        &:hover > .offerButtonText {
            color: light-dark(var(--text-color-reverse-light), var(--text-color-reverse-dark));
        }
        &:hover > .offerButtonSvg {
            fill: light-dark(var(--text-color-reverse-light), var(--text-color-reverse-dark));
        }
    }
    &ButtonSvg {
        margin: 0 3px 0 0;
        transition: color 0.2s ease;
        fill: light-dark(var(--text-color-light), var(--text-color-dark));
    }
    &ButtonText {
        font-weight: 400;
        font-size: 12px;
        transition: color 0.2s ease;
        color: light-dark(var(--text-color-light), var(--text-color-dark));
    }
}

.docs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}
.docsLink {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 30px;
    text-align: center;
    color: #8b8b8b;
    &:hover {
        text-decoration: underline;
    }
}

.main {
    padding: 72px 0 0 255px;
    background-color: light-dark(var(--second-color-light), var(--second-color-dark));
    height: 100%;
    min-height: max-content;
}

.overlay {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}
