@media (max-width: 980px) {
    .aside {
        left: -100%;
        width: 310px;
    }
    .main {
        padding: 72px 0 0 0;
    }
    .profile {
        background-color: transparent;
        &:hover {
            background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        }
    }
    .activeProfile {
        width: 171px;
        background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        border-radius: 10px;
        border: none;
    }
    .titleWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        padding: 25px 15px;
    }
    .logo {
        margin: 0 auto;
    }
    .burger {
        display: flex;
        position: relative;
        top: -1px;
        width: 16px;
        height: 8px;
        cursor: pointer;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        span {
            display: flex;
            position: absolute;
            top: 50%;
            width: 16px;
            height: 2px;
            border-radius: 10px;
            background-color: var(--text-color-dark);
            transition: all 0.3s ease !important;
            &:first-child {
                top: 0;
            }
            &:last-child {
                top: 100%;
                left: 0;
                width: 12px;
            }
        }
    }
    .item {
        padding: 0 15px 15px 15px;
        &:not(:last-child) {
            border-bottom: none;
        }
        &:not(:nth-child(2)) {
            margin: 20px 0 0 0;
        }
    }
    .navButton {
        border-radius: 10px;
    }
    .active {
        border-right: none;
    }
}
.open {
    left: 0;
    z-index: 11;
}
.overlay {
    position: fixed;
    opacity: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный */
    z-index: 10; /* Размещаем поверх контента */
}
