@media (max-width: 1250px) {
    .footer {
        padding: 23px 0 23px 265px;
    }
}
@media (max-width: 980px) {
    .footer {
        padding: 23px 0 23px 25px;
    }
}
