.periods {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 32px 32px 0;

    &Container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 24px;
    }

    &Button {
        font-weight: 400;
        font-size: 14px;
        color: #8b8b8b;
        background-color: inherit;
        transition: color 0.2s ease;
        &:hover {
            color: light-darl(var(--text-color-light), var(--text-color-dark));
        }
    }
    &Active {
        color: light-darl(var(--text-color-light), var(--text-color-dark));
    }
}
