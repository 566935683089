.main {
    display: flex;
    flex-direction: column;
    gap: 28px;
    padding: 20px;
    background-color: light-dark(var(--color-light), var(--color-dark));
    border-radius: 10px;
    width: max-content;
    &Title,
    &Social {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }
    &Container {
        display: flex;
        gap: 75px;
        width: max-content;
    }
    &Wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &Block {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    &Checkboxes {
        display: flex;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 30px;
        max-width: 300px;
    }
    &Switch {
        position: relative;
        display: inline-block;
        width: 36px;
        height: 20px;
    }
    &Slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #d2d5da;
        transition: 0.4s;
        border-radius: 34px;
        &:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            border-radius: 50%;
            left: 2px;
            bottom: 2px;
            background-color: #fff;
            transition: 0.4s;
        }
    }
    &Input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    &Input:checked + &Slider {
        background-color: #2663ea;
    }
    &Input:checked + &Slider:before {
        transform: translateX(16px);
    }
}

.checkbox {
    &Label {
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        border-radius: 6px;
        margin-right: 11px;
        overflow: hidden;
        transition: all 0.1s ease;
        display: inline-block;
        &:active {
            transform: scale(0.9);
        }
        & span {
            float: left;
            vertical-align: middle;
            transform: translate3d(0, 0, 0);
        }

        & span:first-child {
            position: relative;
            width: 18px;
            height: 18px;
            border-radius: 5px;
            transform: scale(1);
            border: 2px solid #cccfdb;
            transition: all 0.2s ease;
        }

        & span:first-child svg {
            position: absolute;
            top: 2px;
            left: 1px;
            fill: none;
            stroke: #fff;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 16px;
            stroke-dashoffset: 16px;
            transition: all 0.3s ease;
            transition-delay: 0.1s;
            transform: translate3d(0, 0, 0);
        }

        & span:last-child {
            padding-left: 8px;
            line-height: 18px;
        }
    }

    &Checkbox {
        width: 0px;
        height: 0px;
        position: relative;
        opacity: 0;
    }

    &Checkbox:checked + &Label span:first-child {
        background: #07f;
        border-color: #07f;
    }

    &Checkbox:checked + &Label span:first-child svg {
        stroke-dashoffset: 0;
    }
    &Checkbox:disabled + &Label {
        cursor: default;
        transform: scale(0.8);
    }
    &Wrapper {
        display: flex;
        align-items: center;
        box-sizing: border-box;
    }
    &Svg {
        position: absolute;
        width: 0;
        height: 0;
        pointer-events: none;
        user-select: none;
    }
}
