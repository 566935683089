.main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 32px;
    max-width: 1500px;
    max-height: calc(100vh - 140px);
    overflow: hidden;
    height: max-content;
    &Back {
        display: flex;
        width: max-content;
        padding: 10px 24px;
        color: #fff;
        background-color: #0062ff;
        border-radius: 8px;
        transition:
            background-color 0.2s ease,
            transform 0.1s ease;
        &:active {
            transform: scale(0.9);
        }
    }
    &Content {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        border-radius: 10px;
        background-color: light-dark(var(--color-light), var(--color-dark));
    }
    &Header {
        display: flex;
        align-items: center;
        padding: 20px;
        gap: 17px;
        border-bottom: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
    }
    &Status {
        font-size: 12px;
        line-height: 14px;
        padding: 10px;
        border-radius: 100px;
        white-space: nowrap;
        &InProgress {
            background-color: #3a2b1f;
            color: #ffb800;
        }
        &Freeze {
            background-color: light-dark(var(--negative-background-color-light), var(--negative-background-color-dark));
            color: light-dark(var(--negative-text-color-light), var(--negative-text-color-dark));
        }
        &Closed {
            background-color: light-dark(var(--positive-background-color-light), var(--positive-background-color-dark));
            color: light-dark(var(--positive-text-color-light), var(--positive-text-color-dark));
        }
    }
    &Title {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Chat {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 480px);
        overflow-y: auto;
        padding: 20px;
        gap: 10px;
        border-bottom: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: light-dark(var(--color-light), var(--color-dark));
            border-radius: 15px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #282c38;
            border-radius: 15px;
        }
        &::-webkit-scrollbar-corner {
            background-color: light-dark(var(--color-light), var(--color-dark));
        }
    }
    &СhatMessage {
        display: flex;
        &Сlient {
            display: flex;
            justify-content: end;
            & > .mainСhatMessageContainer {
                background-color: #4089ff;
            }
        }
        &Container {
            border-radius: 12px;
            background-color: #003c9c;
            padding: 14px 16px;
            max-width: 550px;
        }
        &Message {
            display: flex;
            gap: 5px;
            flex-direction: column;
        }
        &Text {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
        }
        &Time {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-align: right;
        }
    }
    &Footer {
        padding: 20px;
        &Input {
            display: none;
        }
        &Label {
            display: flex;
            max-height: max-content;
            max-width: max-content;
            &Span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background-color: #8ca8d3;
                cursor: pointer;
            }
        }
        &Wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &Item {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        &TextareaWrapper {
            position: relative;
            display: flex;
            width: 560px;
            overflow: hidden;
            border-radius: 12px;
            border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        }
        &Textarea {
            resize: none;
            width: 560px;
            height: 42px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            padding: 12px;
            box-sizing: border-box;
            overflow-y: auto;
            background-color: initial;
            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                background-color: light-dark(var(--color-light), var(--color-dark));
                border-radius: 15px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #282c38;
                border-radius: 15px;
            }
            &::-webkit-scrollbar-corner {
                background-color: light-dark(var(--color-light), var(--color-dark));
            }
        }
        &Button {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #fff;
            padding: 10px 24px;
            background-color: #0062ff;
            border-radius: 8px;
            transition:
                background-color 0.2s ease,
                transform 0.1s ease;
            &:active {
                transform: scale(0.9);
            }
        }
    }
}
