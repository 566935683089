.auth {
    display: flex;
    justify-content: center;
    height: 100%;
    min-width: 100%;
    &Container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 510px;
        gap: 26px;
    }
    &Title {
        font-weight: 700;
        font-size: 32px;
        line-height: 37px;
    }
    &Form {
        display: flex;
        flex-direction: column;

        gap: 26px;
    }
    &Wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-color: #fff;
    }
    &Input {
        -webkit-appearance: none; /* Для WebKit-браузеров (Chrome, Safari) */
        -moz-appearance: none; /* Для Firefox */
        appearance: none;
        width: 100%;
        min-height: 48px;
        padding: 15px 17px;
        border: 1px solid #2d2d2d;
        border-radius: 10px;
        font-weight: 400;
        font-size: 16px;
        background-color: initial;
        & option {
            margin: 10px 0 0 0;
            padding: 15px 17px;
            border: 1px solid #2d2d2d;
            border-radius: 100px;
            font-size: 14px;
            color: #8b8b8b;
        }
    }

    &InputPass {
        width: 100%;
        min-height: 50px;
        max-height: 50px;
        padding: 15px 17px;
        border: 1px solid #2d2d2d;
        border-right: none;
        border-radius: 10px 0 0 10px;
        font-weight: 400;
        font-size: 16px;
        background-color: initial;
    }
    &Link {
        text-decoration: underline;
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }
    &Text {
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }
    &Pass {
        width: max-content;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #c4c4c4;
        text-decoration: underline;
    }
    &Eye {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 50px;
        max-height: 50px;
        padding: 0 17px 0 0;
        border: 1px solid #2d2d2d;
        border-left: none;
        border-radius: 0 10px 10px 0;
    }
    &Status {
        display: flex;
        align-items: center;
        height: 15px;
        gap: 10px;
    }
    &Button {
        width: 100%;
        height: 48px;
        background-color: #212121;
        border-radius: 30px;
        color: #8f8f8f;
        font-weight: 400;
        font-size: 16px;
    }
    &Footer {
        display: flex;
        justify-content: center;
    }
    &Errors {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 5px 0 0 0;
    }
    &Info {
        display: flex;
        background-color: #ffbc5833;
        align-items: center;
        padding: 15px 21px 15px 13px;
        border-radius: 25px 25px 25px 0;
        gap: 8px;
    }

    &ExclamationSvg path {
        stroke: #ffc155;
    }
    &InfoText {
        font-weight: 400;
        font-size: 14px;
        color: #ffdc9f;
    }
    &Explanation {
        display: flex;
        border-radius: 0 0 25px 25px;
        background-color: #58a9ff1a;
        padding: 15px 21px 15px 13px;
        gap: 8px;
    }
    &ExplanationText {
        display: inline-flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #9fc7ff;
    }
}

.inputWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
}

.invalid {
    border-color: #ff4d4d;
    transition: border 0.3s ease;
}

.tooltip {
    position: absolute;
    top: -28px;
    left: 5px;
    color: white;
    font-size: 12px;
    background-color: #222;
    padding: 5px 10px;
    border-radius: 4px;
    white-space: nowrap;
    border: 1px solid #4a4a4a;
    animation: fadeIn 0.3s ease-in-out;

    pointer-events: none;
    user-select: none;
}

.tooltip::after {
    content: "";
    position: absolute;
    top: 20px;
    left: 10px;
    min-width: 5px;
    min-height: 5px;
    background-color: #222;
    transform: rotate(45deg);
    border-right: 1px solid #4a4a4a;
    border-bottom: 1px solid #4a4a4a;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
.authButton:disabled {
    background-color: #2d2d2d;
}

.valid {
    background-color: #0062ff;
    color: #fff;
}

.valid:hover {
    background-color: #4089ff;
}

.valid:active {
    background-color: #387ae5;
}

.checkboxWrapper {
    position: relative;
}
.checkboxContainer {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    color: #8b8b8b;
}

.checkboxContainer input {
    display: none;
}

.customCheckbox {
    min-width: 18px;
    min-height: 18px;
    border-radius: 50%;
    background-color: #fff;
    border: 3px solid #333333;
    position: relative;
    transition: all 0.2s ease;
}

.checkboxContainer input:checked + .customCheckbox {
    background-color: #00ff2b;
}

.checkboxContainer input:checked + .customCheckbox::after {
    content: "";
    font-size: 14px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.textCheckbox {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
}
.linkCheckbox {
    color: #c4c4c4;
    text-decoration: underline;
    &:hover {
        color: #fff;
        text-decoration: underline;
    }
}

.resendButton,
.resendTimer {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}
.resendButton {
    font-weight: 700;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px #121212 inset !important;
}
.dialog {
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    &::backdrop {
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(5px);
    }
    &Container {
        display: flex;
        flex-direction: column;
        padding: 40px;
        gap: 50px;
        width: 550px;
    }
    &Header {
        display: flex;
        justify-content: space-between;
    }
    &Title {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
    }
    &Content {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    &Label {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    &Span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Input {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding: 17px 14px;
        border: 1px solid #1e1e1e;
        border-radius: 10px;
    }
    &Button {
        padding: 11px 0;
        border-radius: 10px;
        background-color: #0062ff;
        transition: transform 0.1s ease;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        &:active {
            transform: scale(0.95);
        }
    }
    &Qr {
        width: 250px;
        height: 250px;
        border-radius: 24px;
    }
}
