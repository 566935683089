.dialog {
    @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        width: 100vw;
        height: 100vh;
        max-width: none;
        max-height: none;
        border: none;
        border-radius: 0;

        &Block {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
        &Container {
            width: 100%;
            height: max-content;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &Content {
            gap: 20px;
        }

        &Label {
            gap: 10px;
        }

        &Input {
            min-height: 45px;
            max-height: 45px;
            padding: 12px 15px;
        }

        &Qr {
            width: 200px;
            height: 200px;
        }
    }

    @media (max-width: 480px) {
        &Container {
            padding: 15px;
            gap: 20px;
        }

        &Content {
            gap: 15px;
        }

        &Label {
            gap: 8px;
        }

        &Input {
            min-height: 40px;
            max-height: 40px;
            padding: 10px 12px;
        }

        &Qr {
            width: 180px;
            height: 180px;
        }
    }
}
