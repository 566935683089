@media (max-width: 1100px) {
    .Authorization {
        &Video {
            display: none;
        }
        &Container {
            width: 100%;
        }
        &Wrapper {
            width: 100%;
            padding: 0 20px 0 0;
        }
    }
}
