.mainProfile {
    display: flex;
    flex-wrap: wrap;
    max-width: 740px;
    max-height: 260px;
    gap: 16px;
    &Title {
        display: inline-flex;
        gap: 7px;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Disabled {
        padding: 3px 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #d67f7f;
        background-color: #250c0c;
        border-radius: 8px;
    }
    &Wrapper {
        display: flex;
        flex-direction: column;
        padding: 20px;
        width: 740px;
        height: 260px;
        border-radius: 10px;
        background-color: light-dark(var(--color-light), var(--color-dark));
        gap: 42px;
    }
    &Block {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &EmailBlock {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 25px;
        width: 360px;
        height: 120px;
    }
    &Email {
        display: flex;
        align-items: center;
        color: #aaaaaa;
        padding: 10px 0;
        width: 100%;
        gap: 6px;
    }
    &Container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &Item {
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 25px;
        width: 360px;
        height: 120px;
    }
    &Button {
        padding: 10px 0;
        width: 100%;
        color: #fff;
        border-radius: 8px;
        background-color: #0062ff;
        transition:
            background-color 0.2s ease,
            transform 0.1s ease;
        &:hover {
            background-color: #003c9c;
        }
        &:active {
            transform: scale(0.95);
        }
    }
}
.dialog {
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    &::backdrop {
        background-color: light-dark(var(--backdrop-color-light), var(--backdrop-color-dark));
        backdrop-filter: blur(5px);
    }
    &Container {
        display: flex;
        flex-direction: column;
        padding: 40px;
        gap: 50px;
        width: 550px;
    }
    &Header {
        display: flex;
        justify-content: space-between;
    }
    &Title {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
    }
    &Content {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    &Label {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    &Span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Button {
        padding: 11px 0;
        border-radius: 10px;
        background-color: #0062ff;
        transition: transform 0.1s ease;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #fff;
        &:active {
            transform: scale(0.95);
        }
    }

    &Wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-color: #fff;
    }
    &InputPass {
        width: 100%;
        min-height: 50px;
        max-height: 50px;
        padding: 15px 17px;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        border-right: none;
        border-radius: 10px 0 0 10px;
        font-weight: 400;
        font-size: 14px;
        background-color: initial;
        &:-webkit-autofill,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 0 light-dark(var(--color-light), var(--color-dark)) !important;
        }
        &:-internal-autofill-selected {
            background-color: #fff !important;
        }
    }
    &Eye {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 50px;
        max-height: 50px;
        padding: 0 17px 0 0;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        border-left: none;
        border-radius: 0 10px 10px 0;
    }
    &Status {
        display: flex;
        align-items: center;
        height: 15px;
        gap: 10px;
    }
    &Errors {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 5px 0 0 0;
    }
}
.tooltip {
    position: absolute;
    top: -28px;
    left: 5px;
    color: white;
    font-size: 12px;
    background-color: #222;
    padding: 5px 10px;
    border-radius: 4px;
    white-space: nowrap;
    border: 1px solid #4a4a4a;
    animation: fadeIn 0.3s ease-in-out;

    pointer-events: none;
    user-select: none;
    &::after {
        content: "";
        position: absolute;
        top: 20px;
        left: 10px;
        min-width: 5px;
        min-height: 5px;
        background-color: #222;
        transform: rotate(45deg);
        border-right: 1px solid #4a4a4a;
        border-bottom: 1px solid #4a4a4a;
    }
}
