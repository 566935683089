.main {
    &Container {
        display: flex;
        flex-direction: column;
        padding: 30px 32px 0;
        gap: 28px;
    }
    &Form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        border-radius: 10px;
        width: 500px;
        gap: 16px;
    }
    &Dialog {
        border: none;
        padding: 0;
        scale: 1.05;
        border-radius: 10px;
        top: 25%;
        left: 35%;
    }
    &Dialog::backdrop {
        background: light-dark(var(--backdrop-color-light), var(--backdrop-color-dark));
    }
    &Wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 22px;
        border-radius: 10px;
        background-color: light-dark(var(--color-light), var(--color-dark));
        padding: 45px 35px;
    }
    &WrapperKeys {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        background-color: light-dark(var(--color-light), var(--color-dark));
        padding: 11px 35px 45px 35px;
        gap: 5px;
    }
    &Box {
        display: flex;
        flex-wrap: wrap;
        gap: 22px;
    }
    &Button {
        padding: 10px 24px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        margin-left: auto;
        background-color: #0062ff;
        color: #fff;
        border-radius: 8px;
        &:hover {
            background-color: #004dca;
        }
        &:active {
            background-color: #003c9c;
        }
    }
    &Block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        gap: 17px;
        width: 360px;
        height: 230px;
        border: 1px dashed #8b8b8b;
        border-radius: 10px;
    }
    &BlockKey {
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        gap: 17px;
        max-width: 360px;
        width: 100%;
        border: 1px dashed #8b8b8b;
        border-radius: 10px;
    }
    &TitleContainer {
        display: flex;
        justify-content: space-between;
        height: max-content;
    }
    &ApiTitle {
        display: inline;
        max-width: 160px;
        max-height: 45px;
        word-wrap: break-word;
        overflow: hidden;
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
    }
    &Created {
        display: inline;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
    }
    &TextContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &Designation {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
    }
    &ButtonContainer {
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;
    }
    &VeiwButton,
    &DeleteButton,
    &EditButton {
        padding: 10px 18px;
        max-width: 100px;
        width: 100%;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        border-radius: 8px;
        transition: transform 0.1s ease;
        color: #fff;
        &:active {
            transform: scale(0.95);
        }
    }
    &VeiwButton {
        background-color: #3774ff;

        &:hover {
            background-color: #004dca;
        }
        &:active {
            background-color: #003c9c;
        }
    }
    &EditButton {
        background-color: #ff7a37;
    }
    &DeleteButton {
        background-color: #ff3737;
        &:hover {
            background-color: #ff0000;
        }
        &:active {
            background-color: #ff5252;
        }
    }
    &BlockTitle {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
    }
    &BlockButton {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: #0062ff;
        &:active {
            color: #003c9c;
        }
    }
    &Text {
        display: flex;
        flex-direction: column;
        max-width: 305px;
        line-height: 20px;
        gap: 20px;
    }
    &TextTitle {
        font-weight: 700;
        font-size: 18px;
    }
    &TextSubtitle {
        font-weight: 400;
        font-size: 18px;
    }
    &Label {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
    }
    &FormHeader {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
    }
    &InputTitle {
        padding: 0 0 0 5px;
    }
    &Input {
        display: flex;
        align-items: center;
        border: 1px solid #2d2d2d;
        border-radius: 10px;
        padding: 8px 16px;
        width: 100%;
        &Api {
            transition: transform 0.1s ease;
            cursor: pointer;
            &:active {
                transform: scale(0.95);
            }
        }
    }
}
.dialog {
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    max-width: 550px;
    &::backdrop {
        background-color: light-dark(var(--backdrop-color-light), var(--backdrop-color-dark));
        backdrop-filter: blur(5px);
    }
    &Container {
        display: flex;
        flex-direction: column;
        padding: 40px;
        gap: 50px;
        width: 100%;
    }
    &ImageWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 26px;
        width: 100%;
    }
    &ImageInfo {
        display: flex;
        flex-direction: column;
        max-width: 200px;
        gap: 12px;
    }
    &ImageText {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #8b8b8b;
    }
    &ImageButtons {
        display: flex;
        gap: 10px;
    }
    &ButtonImage {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        padding: 12px 16px;
        border-radius: 27px;
        background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        transition: background-color 0.2s ease;
        &:hover {
            background-color: #0062ff;
        }
        &:active {
            background-color: #003c9c;
        }
    }
    &ImageZone {
        max-width: 225px;
        min-width: 225px;
        max-height: 125px;
        min-height: 125px;
        border-radius: 10px;
        background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        object-fit: cover;
    }
    &Header {
        display: flex;
        justify-content: space-between;
    }
    &Title {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
    }
    &Content {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    &Label {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 12px;
        -webkit-user-select: none;
        user-select: none;
    }
    &Span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Input {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding: 17px 14px;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        border-radius: 10px;
        background-color: initial;
        &Another {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            width: 100%;
            cursor: pointer;
            background-color: inherit;
        }
    }
    &Buttons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 100%;
    }
    &Button {
        padding: 11px 0;
        border-radius: 10px;
        background-color: #0062ff;
        transition: transform 0.1s ease;
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #fff;
        &:active {
            transform: scale(0.95);
        }
        &Save {
            position: relative;
        }
    }
    &CustomizeButton {
        display: inline-flex;
        align-items: center;
        gap: 3px;
        width: max-content;
        padding: 10px 20px;
        background-color: #ff7a37;
        border-radius: 8px;
        color: #fff;
        transition: transform 0.1s ease;
        &:active {
            transform: scale(0.95);
        }
    }
    &Commission {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        align-items: center;
        &Text {
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
        }
        &Percent {
            display: inline-flex;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            padding: 8px 16px;
            border-radius: 100px;
            color: light-dark(var(--positive-text-color-light), var(--positive-text-color-dark));
            background-color: light-dark(var(--positive-background-color-light), var(--positive-background-color-dark));
        }
    }
    &Radio {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 11px;
        &Wrapper {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
        &Label {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            gap: 5px;
            cursor: pointer;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
        }
        &Span {
            min-width: 18px;
            min-height: 18px;
            border-radius: 50%;
            background-color: initial;
            border: 2px solid #dbdbdb;
            position: relative;
            transition: background-color 0.2s ease;
            &RadioChecked {
                background-color: #0062ff;
            }
        }
        &Input {
            display: none;
        }
    }
}
.imagePreviewWrapper {
    display: flex;
    align-items: center;
}

.imagePreview {
    border: 1px solid #ddd;
    margin-right: 10px;
    position: relative;
}

.imagePreviewEmpty {
    background-color: #f0f0f0;
}

.addButton,
.changeButton,
.removeButton {
    margin: 5px;
}

.addButton {
    background-color: #4caf50;
    color: white;
}

.changeButton {
    background-color: #ffd700;
    color: white;
}

.removeButton {
    background-color: #f44336;
    color: white;
}
.tooltip {
    position: absolute;
    color: light-dark(var(--text-color-light), var(--text-color-dark));
    background-color: light-dark(var(--color-light), var(--color-dark));
    border: 1px solid #0062ff;
    width: max-content;
    padding: 3px 6px;
    border-radius: 5px;
    top: 7px;
    left: 16%;
    z-index: 10;
    font-size: 12px;
}
