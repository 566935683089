@media (max-width: 1250px) {
    .topups {
        padding: 3px 25px 0;
        &Info {
            flex-direction: column;
            align-items: flex-start;
            margin: 15px 0 0 0;
            gap: 3px;
        }
        &Percent {
            align-self: end;
        }
    }
}
@media (max-width: 1060px) {
    .topups {
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 10px;
        &Item {
            flex: 1 1 calc(50% - 10px);
        }
        &Info {
            flex-direction: row;
        }
    }
}
@media (max-width: 576px) {
    .topups {
        &Item {
            padding: 15px;
        }
        &Info {
            flex-direction: column;
        }
    }
}
