@media (max-width: 1100px) {
    .auth {
        &Container {
            display: flex;
            max-width: 510px;
            width: 100%;
            padding: 0 20px;
        }
    }
}
