@media (max-width: 768px) {
    .dialog {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        width: 100vw;
        height: 100vh;
        max-width: none;
        max-height: none;
        border: none;
        border-radius: 0;
        &Block {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
}
