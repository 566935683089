.footer {
    background-color: light-dark(var(--second-color-light), var(--second-color-dark));
    width: 100%;
    padding: 23px 0 23px 272px;
    margin-top: auto;
    &Text {
        font-weight: 400;
        font-size: 14px;
        color: #8b8b8b;
    }
}
