.balance {
    width: 100%;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    &Info {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 26px;
        &Wrapper {
            display: flex;
            flex: 1;
            flex-direction: column;
            min-height: 265px;
            padding: 20px 0;
            border-radius: 10px;
            background-color: light-dark(var(--color-light), var(--color-dark));
        }
        &Amount {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 15px 20px;
            border-bottom: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
            gap: 13px;
        }
        &IconWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 38px;
            height: 38px;
            border-radius: 13px;
            background-color: #d8e7ff;
            box-shadow: 0px 4px 4px 0px var(--shadow-color);
        }
        &Balance {
            display: flex;
            flex-direction: column;
            gap: 3px;
        }
        &Title {
            font-weight: 400;
            font-size: 12px;
        }
        &Sum {
            font-weight: 400;
            font-size: 18px;
        }
        &Сurrency {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 12px 14px;
            gap: 12px;
        }
        &Item {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 8px 4px 8px;
            gap: 7px;
            border-radius: 5px;
            background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        }
        &Property {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        &Value {
            font-weight: 400;
            font-size: 12px;
            color: #959595;
        }
        &Rate {
            font-weight: 400;
            font-size: 10px;
            color: #565656;
        }
        &Buttons {
            display: flex;
            justify-content: end;
            margin-top: auto;
            padding: 20px 15px 0 15px;
            gap: 10px;
        }
        &Button {
            display: flex;
            gap: 8px;
            align-items: center;
            padding: 11px 24px;
            border-radius: 8px;
            color: #fff;
            background-color: #2d7dff;
            transition:
                background-color 0.2s ease,
                transform 0.1s ease;
            &:active {
                transform: scale(0.95);
            }
            &:hover {
                background-color: #4089ff;
            }
        }
        &ButtonMultiple {
            display: flex;
            gap: 8px;
            align-items: center;
            padding: 11px 24px;
            border-radius: 8px;
            background-color: initial;
            box-sizing: content-box;
            border: 1px solid #2d7dff;
            transition:
                color 0.2s ease,
                border-color 0.2s ease,
                background-color 0.2s ease,
                transform 0.1s ease;
            &:active {
                transform: scale(0.95);
            }
            &:hover {
                color: #fff;
                border-color: #4089ff;
                background-color: #4089ff;
            }
            &:hover > .balanceInfoButtonMultipleSvg {
                fill: #fff;
            }
        }
        &ButtonMultipleSvg {
            transition: fill 0.2s ease;
            fill: light-dark(var(--text-color-light), var(--text-color-dark));
        }
        &Container {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 100%;
            gap: 24px;
            flex: 1;
            max-height: 265px;
        }
        &Block {
            display: flex;
            flex-direction: column;
            padding: 20px;
            border-radius: 10px;
            background-color: light-dark(var(--color-light), var(--color-dark));
            justify-content: space-between;
            flex: 1 1 calc(50% - 10px);
            max-height: 120px;
            min-height: 120px;
        }
        &Label {
            font-weight: 400;
            font-size: 15px;
        }
        &Count {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &Asset {
            font-weight: 400;
            font-size: 25px;
            &PaidColor {
                color: #25ca00;
            }
            &PartiallyColor {
                color: #ff7a37;
            }
            &UnpaidColor {
                color: #ff3737;
            }
        }
        &Percent {
            display: inline-flex;
            align-self: flex-end;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            padding: 3px 11px;
            border-radius: 14px;
            background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        }
    }
    &Creatе {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        background-color: #0d0d0d;
        border-radius: 10px;
        padding: 32px 20px;

        &Text {
            max-width: 550px;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
        }
        &Button {
            border-radius: 8px;
            padding: 10px 24px;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            background-color: #0062ff;
            transition: background-color 0.2s ease;
            word-wrap: nowrap;
            &:hover {
                background-color: #4089ff;
            }
        }
        &Dialog {
            margin: auto;
            width: max-content;
            height: max-content;
            border-radius: 10px;
            width: 550px;
        }
        &Wrapper {
            display: flex;
            flex-direction: column;
            padding: 40px;
            gap: 50px;
        }
        &Header {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
        }
        &Title {
            font-weight: 400;
            font-size: 28px;
            line-height: 32px;
        }
        &Form {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
        &Label {
            display: inline-flex;
            flex-direction: column;
            gap: 12px;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
        }
        &Input {
            padding: 17px 14px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            border-radius: 10px;
            border: 1px solid #1e1e1e;
        }
        &DialogButton {
            padding: 11px 0;
            border-radius: 10px;
            background-color: #0062ff;
            color: #fff;
            transition:
                transform 0.1s ease,
                background-color 0.2s ease;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;

            &:active:not(:disabled) {
                transform: scale(0.95);
            }

            &:hover:not(:disabled) {
                background-color: #4089ff;
            }

            &:disabled {
                cursor: not-allowed;
                opacity: 0.7;
            }
        }
    }
    &Container {
        width: 100%;
        background-color: light-dark(var(--color-light), var(--color-dark));
        border-radius: 10px;
        padding: 20px 0 0 0;
    }
    &Wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 20px 30px 20px;
    }
    &Title {
        font-weight: 400;
        font-size: 16px;
        color: light-dark(var(--text-color-light), var(--text-color-dark));
    }
    &Line {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        min-height: 32px;
        padding: 4px 50px 4px 29px;
        background-color: light-dark(var(--info-background-color-light), var(--info-background-color-dark));
        border: 1px solid light-dark(var(--info-border-color-light), var(--info-border-color-dark));
        gap: 30px;
    }
    &Text {
        display: inline-flex;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        color: #8b8b8b;
        cursor: pointer;
    }
    &Search {
        position: relative;
        width: 100%;
        max-width: 540px;
        padding: 4px 4px 4px 12px;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        border-radius: 12px;
        gap: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 auto 13px auto;
        &Input {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            width: 100%;
        }
        &Wrapper {
            width: 100%;
            padding: 0 20px;
        }
    }
    &Input {
        width: 350px;
        min-width: 0;
        background-color: initial;
        font-weight: 400;
        font-size: 14px;
    }
    &Сalendar {
        display: flex;
        flex-direction: row;
        line-height: 14px;
        gap: 4px;
        text-wrap: nowrap;
        font-weight: 400;
        font-size: 12px;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        border-radius: 9px;
        padding: 4px 10px;
        cursor: pointer;
    }
    &СalendarSvg {
        stroke: light-dark(var(--text-color-light), var(--text-color-dark)) !important;
    }
    &Item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 10px;
        height: max-content;
    }
    &Button {
        display: flex;
        align-items: center;
        background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        border-radius: 100px;
        font-weight: 400;
        font-size: 12px;
        padding: 4px 6px;
        gap: 4px;
        transition: transform 0.1s ease;
        &:active {
            transform: scale(0.95);
        }
    }
    &Lupa {
        min-width: 12px;
        min-height: 12px;
    }
    &Preferences {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin: 0 0 30px 0;
        gap: 8px;
    }
    &PreferencesButton {
        font-weight: 400;
        font-size: 12px;
        padding: 4px 10px;
        border-radius: 12px;
        border: 1px dashed light-dark(var(--border-color-light), var(--border-color-dark));
    }
    &ClearButton {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: initial;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
    }
    &FilterDropdown {
        position: absolute;
        width: 170px;
        max-height: 188px;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        border-radius: 10px;
        background-color: light-dark(var(--color-light), var(--color-dark));
        margin: 33px 0 0 0;
        overflow: hidden;
        z-index: 1;
    }
    &FilterSearch {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 8px 13px;
        border-bottom: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        gap: 10px;
        align-items: center;
    }
    &FilterInput {
        max-width: 120px;
        font-weight: 400;
        font-size: 14px;
        background-color: initial;
    }
    &FilterUl {
        margin: 5px 0;
        max-height: 112px;
        overflow-y: auto;
        &::-moz-scrollbar,
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-moz-scrollbar-corner,
        &::-webkit-scrollbar-track {
            background-color: light-dark(var(--second-color-light), var(--second-color-dark));
            border-radius: 15px;
        }
        &::-moz-scrollbar-thumb,
        &::-webkit-scrollbar-thumb {
            background-color: #282c38;
            border-radius: 15px;
        }
        &::-moz-scrollbar-corner,
        &::-webkit-scrollbar-corner {
            background-color: light-dark(var(--color-light), var(--color-dark));
        }
    }
    &FilterItem {
        cursor: pointer;
        display: inline-flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        padding: 7px 13px;
        &:hover {
            background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        }
    }
    &FilterArrow {
        transform: rotate(-90deg);
    }
    &FilterTag {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        gap: 5px;
        padding: 0 0 0 6px;
        font-weight: 400;
        font-size: 12px;
    }
    &DialogFilterCheckbox {
        appearance: none;
        -webkit-appearance: none;
        width: 11px;
        height: 11px;
        border: 1px solid #dbdbdb;
        border-radius: 2px;
        position: relative;
        cursor: pointer;
        transition: background-color 0.2s;
        &:checked {
            background-color: #0062ff;
        }
        &:disabled {
            background-color: #6fa6ff;
        }
    }
    &Cross {
        max-width: 12px;
        max-height: 12px;
    }
    &CrossButton {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 20px;
        border-left: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
    }
    &BackButton {
        display: flex;
        align-items: center;
        padding: 10px 13px;
        width: 100%;
        gap: 10px;
        border-top: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        font-weight: 400;
        font-size: 12px;
    }
    &BackArrow {
        transform: rotate(90deg);
    }
    &Dialog {
        margin: auto;
        width: max-content;
        height: max-content;
        border-radius: 10px;
        &::backdrop {
            background-color: rgba(0, 0, 0, 0.6);
            backdrop-filter: blur(5px);
        }
        &Header {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            padding: 40px 40px 0 40px;
            font-weight: 400;
            font-size: 28px;
        }
        &Wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 54px 40px 18px;
            justify-content: space-between;
            border-bottom: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
            font-weight: 400;
            font-size: 18px;
            line-height: 33px;
        }
        &Sub {
            display: inline-flex;
            align-items: center;
            line-height: 21px;
            gap: 16px;
            cursor: pointer;
            font-weight: 400;
            font-size: 18px;
            padding: 6px 5px;
            border-radius: 5px;
            transition: background-color 0.2s ease;
            &:hover {
                background-color: light-dark(var(--second-color-light), var(--second-color-dark));
            }
        }
        &Ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 25px 40px 50px;
        }
        &Label {
            width: 100%;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 6px 5px;
            border-radius: 5px;
            height: 30px;
            transition: background-color 0.2s ease;
            font-weight: 400;
            font-size: 16px;
            &:hover {
                background-color: light-dark(var(--second-color-light), var(--second-color-dark));
            }
        }
        &Buttons {
            display: flex;
            justify-content: space-between;
            padding: 0 40px 40px 40px;
            gap: 23px;
        }
        &Close,
        &Save {
            width: 100%;
            height: 40px;
            border-radius: 10px;
            font-weight: 400;
            font-size: 12px;
        }
        &Close {
            background-color: initial;
            color: #0062ff;
            border: 1px solid #0062ff;
            transition:
                color 0.2s ease,
                background-color 0.2s ease;
            &:hover {
                color: #fff;
                background-color: #4089ff;
                border-color: #4089ff;
            }
        }
        &Save {
            background-color: #0062ff;
            color: #fff;
            transition: background-color 0.2s ease;
            &:hover {
                background-color: #4089ff;
            }
        }
        &Checkbox {
            appearance: none;
            -webkit-appearance: none;
            width: 18px;
            height: 18px;
            border: 2px solid #dbdbdb;
            border-radius: 5px;
            position: relative;
            cursor: pointer;
            transition: background-color 0.2s;
            &:checked {
                background-color: #0062ff;
            }
            &:disabled {
                background-color: #6fa6ff;
            }
        }
    }
}
.list {
    min-height: 250px;
    max-height: calc(100vh - 645px);
    overflow-y: auto;
    direction: rtl;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: light-dark(var(--color-light), var(--color-dark));
        border-radius: 15px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #282c38;
        border-radius: 15px;
    }
    &::-webkit-scrollbar-corner {
        background-color: light-dark(var(--color-light), var(--color-dark));
    }
    &Item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 0 50px 0 25px;
        width: 100%;
        height: 48px;
        border-bottom: 1px solid light-dark(var(--info-border-color-light), var(--info-border-color-dark));
        direction: ltr;
        gap: 30px;
    }

    &Wrapper {
        font-weight: 400;
        font-size: 14px;
        color: #8b8b8b;
        display: flex;
        flex-direction: row;
        justify-content: center;
        white-space: nowrap;
        height: 14px;
    }
    &StatusTrue {
        font-weight: 700;
        font-size: 14px;
        position: absolute;
        padding: 2px 8px;
        border-radius: 4px;
        top: -2px;
        text-transform: uppercase;
        color: light-dark(var(--true-color-light), var(--true-color-dark));
        background-color: light-dark(var(--true-text-color-light), var(--true-text-color-dark));
    }
    &StatusFalse {
        font-weight: 700;
        font-size: 14px;
        position: absolute;
        padding: 2px 8px;
        padding: 2px 8px;
        border-radius: 4px;
        top: -2px;
        color: #ffcc85;
        text-transform: uppercase;
        background-color: var(--false-color-dark);
    }
    &Id {
        width: 47px;
    }
    &Hash {
        width: 190px;
    }
    &Api {
        width: 100px;
        height: max-content;
        text-wrap: wrap;
        word-break: break-all;
        text-align: left;
    }
    &Label {
        width: 100px;
        height: max-content;
        text-wrap: wrap;
        word-break: break-all;
        text-align: left;
    }
    &Token {
        width: 100px;
    }
    &Amount {
        width: 100px;
    }
    &Paid {
        position: relative;
        width: 140px;
    }
    &Sent {
        position: relative;
        width: 140px;
    }
    &Time {
        width: 115px;
    }
    &NoTransactions {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        &Title {
            font-weight: 400;
            font-size: 16px;
            text-align: center;
        }
        &Subtitle {
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            max-width: 200px;
            line-height: 14px;
        }
    }
}
.calendarContainer {
    background-color: light-dark(var(--second-color-light), var(--second-color-dark));
    border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
    overflow: hidden;
    border-radius: 8px;
    padding: 20px;
    position: absolute;
    width: max-content;
    height: max-content;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    margin: 37px auto 0 auto;
    &Buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &allTimeButton {
        background-color: #0062ff;
        color: #fff;
        height: 32px;
        width: 140px;
        border-radius: 8px;
        transition:
            background-color 0.2s ease,
            transform 0.1s ease;
        &:hover {
            background-color: #4089ff;
        }
        &:active {
            transform: scale(0.95);
        }
    }
    &closeButton {
        background-color: initial;
        color: #0062ff;
        border: 1px solid #0062ff;
        height: 32px;
        width: 140px;
        border-radius: 8px;
        transition:
            background-color 0.2s ease,
            transform 0.1s ease;
        &:hover {
            background-color: #4089ff;
            color: #fff;
        }
        &:active {
            transform: scale(0.95);
        }
    }
}

.applyButton:hover {
    background-color: #388e3c;
}
.dialog {
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    &::backdrop {
        background-color: light-dark(var(--backdrop-color-light), var(--backdrop-color-dark));
        backdrop-filter: blur(5px);
    }
    &Container {
        display: flex;
        flex-direction: column;
        width: 550px;
        &Withdrawal {
            padding: 40px;
            gap: 50px;
        }
    }
    &Header {
        display: flex;
        justify-content: space-between;
    }
    &Title {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
    }
    &Content {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    &Label {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    &Span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Input {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding: 17px 14px;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        border-radius: 10px;
        background-color: initial;
    }
    &Button {
        padding: 11px 0;
        border-radius: 10px;
        background-color: #0062ff;
        color: #fff;
        transition: transform 0.1s ease;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        &:active {
            transform: scale(0.95);
        }
    }
}

.textareaContainer {
    position: relative;
    display: flex;
    width: 100%;
    border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
    border-radius: 10px;
    overflow: hidden;
    background-color: light-dark(var(--second-color-light), var(--second-color-dark));
}

.lineNumbers {
    display: flex;
    flex-direction: column;
    padding: 10px 8px;
    border-right: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
    user-select: none;
    color: light-dark(var(--text-color-light), var(--text-color-dark));
    opacity: 0.6;
    text-align: right;
    min-width: 40px;
}

.lineNumber {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    white-space: nowrap;
    position: relative;
    transition:
        color 0.2s,
        font-weight 0.2s;

    &Limit {
        color: #ff3737;
        font-weight: 700;
    }
}

.dialogTextarea {
    flex: 1;
    border: none;
    padding: 10px;
    resize: vertical;
    background-color: transparent;
    color: light-dark(var(--text-color-light), var(--text-color-dark));
    outline: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    min-height: 120px;
    overflow-y: hidden;
    white-space: pre;

    &::placeholder {
        color: rgba(139, 139, 139, 0.6);
    }
}

.dialogNote {
    font-size: 12px;
    color: light-dark(var(--text-color-light), var(--text-color-dark));
    opacity: 0.7;
    margin-top: 5px;
    display: block;

    &Limit {
        color: #ff3737;
        font-weight: 700;
    }
}

.dialogLabelHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.dialogExampleButton {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    background-color: transparent;
    color: #2d7dff;
    cursor: pointer;
    transition: all 0.2s ease;
    &:active {
        transform: scale(0.95);
    }
}

.dialogButtonLoading {
    position: relative;
    cursor: wait;

    &::after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        width: 16px;
        height: 16px;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-top: 2px solid #fff;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
