.profile {
    height: min-content;
    &Avatar {
        width: 108px;
        height: 108px;
        border-radius: 50%;
    }
    &Wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 32px;
        margin: 0 0 20px 0;
    }
    &Title {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
    }
    &Nav {
        display: flex;
        align-items: center;
        max-width: 740px;
        margin: 0 32px;
    }
    &Nav ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: 14px;
    }
    &Button {
        display: inline-flex;
        align-items: center;
        min-width: 100px;
        gap: 5px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        cursor: pointer;
        line-height: 15px;
        padding: 6px 16px;
        border-radius: 6px;
        border: 1px solid #448cff;
        color: #448cff;
        transition:
            color 0.2s ease,
            transform 0.1s ease;
        & > .profileSvg {
            fill: #448cff;
        }
    }
    &Button:hover {
        border-color: #003c9c;
        background-color: #003c9c;
    }

    &Button:hover,
    &Button:hover > .profileSvg {
        color: #fff;
        fill: #fff;
    }
    &Button:active {
        transform: scale(0.95);
    }
    &Svg {
        min-width: 15px;
        min-height: 15px;
        fill: #8b8b8b;
        transition: fill 0.2s ease;
    }
    &Content {
        margin: 30px 32px 0;
        border-radius: 10px;
    }
}
.active,
.active > .profileSvg {
    color: #fff;
    fill: #fff;
}
.active {
    border-color: #0062ff;
    background-color: #0062ff;
}
