.header {
    position: fixed;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 20px 35px 20px 287px;
    width: 100%;
    z-index: 1;
    background-color: light-dark(var(--second-color-light), var(--second-color-dark));
    &Wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 10px;
    }
    &Button {
        min-width: 22px !important;
        max-width: 22px;
        min-height: 22px !important;
        max-height: 22px;
    }
    &Container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 13px;
    }
    &Sum {
        font-weight: 600;
        font-size: 18px;
        line-height: auto;
        cursor: default;
    }
    &Menu {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 6px;
        cursor: pointer;
    }
    &Image {
        border-radius: 50%;
        width: 26px;
        height: 26px;
    }
    &Dropdown {
        position: absolute;
        right: 32px;
        top: 55px;
        width: 320px;
        background-color: #0d0d0d;
        border-radius: 20px;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        overflow: hidden;

        background-color: light-dark(var(--color-light), var(--color-dark));
    }
    &DropdownImage {
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }
    &Li {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        margin: 24px auto 10px auto;
        border-radius: 10px;
        padding: 10px 36px;
        width: max-content;
        transition: background-color 0.2s ease;
        &:hover {
            background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        }
    }
    &Text {
        display: flex;
        flex-direction: column;
        padding: 4px 0 0 0;
        gap: 4px;
    }
    &Username {
        font-weight: 400;
        font-size: 16px;
        color: light-dark(var(--text-color-light), var(--text-color-dark));
    }
    &Small {
        font-weight: 400;
        font-size: 14px;
        color: #0062ff;
    }
    &Preferences,
    &PreferencesLang {
        padding: 16px 24px;
        cursor: pointer;
        transition: background-color 0.2s ease;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 8px;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        height: 50px;
        &:hover {
            background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        }
    }
    &PreferencesLang {
        margin: 0 0 12px 0;
    }
    &PreferencesBack {
        font-weight: 400;
        font-size: 18px;
        padding: 16px 0 16px 24px;
        margin: 0 0 12px 0;
        border-bottom: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        transition: background-color 0.2s ease;
        cursor: pointer;
        &:hover {
            background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        }
    }
    &Logout {
        padding: 20px 24px;
        border-top: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        cursor: pointer;
        transition: background-color 0.2s ease;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 8px;
        color: #ff4242;
        font-weight: 400;
        font-size: 16px;
        &:hover {
            background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        }
    }
    &Arrow {
        transform: rotate(0deg);
        transition: transform 0.2s ease;
    }
    &ArrowOpen {
        transform: rotate(180deg);
    }
}

.burger {
    display: none;
}

.title {
    font-weight: 400;
    font-size: 28px;
    color: light-dark(var(--text-color-light), var(--text-color-dark));
}

.container,
.wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
.container {
    gap: 16px;
}

.searchForm {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    align-items: center;
    width: 350px;
    min-width: none;
    height: 32px;
    color: light-dark(var(--text-color-light), var(--text-color-dark));
    background-color: light-dark(var(--color-light), var(--color-dark));
    border: 1px solid light-dark(var(--search-border-color-light), var(--search-border-color-dark));
    border-radius: 12px;
    margin: 0 17px 0 0;
}
.searchSvg {
    display: flex;
    min-width: 12px;
    min-height: 12px;
    margin: 0 13px;
    background-color: transparent;
}
.searchCross {
    display: flex;
    align-items: center;
    margin: 0 13px;
    background-color: transparent;
    opacity: 0;
    cursor: default;
    transition: opacity 0.2s ease;
}
.crossSvg {
    width: 12px;
    height: 12px;
}
.searchInput {
    width: 100%;
    background-color: light-dark(var(--color-light), var(--color-dark));
    color: light-dark(var(--text-color-light), var(--text-color-dark));
    &::-webkit-search-cancel-button,
    &::-moz-search-clear {
        display: none;
    }
    &::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: light-dark(var(--serch-placeholder-color-light), var(--serch-placeholder-color-dark));
    }
    &:focus + .searchCross,
    &:not(:placeholder-shown) + .searchCross {
        opacity: 1;
        cursor: pointer;
    }
}

.wrapper {
    gap: 8px;
}
.ulSvg {
    min-width: 18px;
    min-height: 18px;
}
.ulSpan {
    min-width: 12px;
    width: 12px;
    height: 12px;
    min-height: 12px;
}

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0062ff;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out;
    &:hover {
        cursor: pointer;
        background-color: #5898ff;
    }
}
.itemStart {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0062ff;
    max-width: 101px;
    width: 100%;
    min-height: 26px;
    padding: 6px 12px;
    border-radius: 12px;
    transition:
        background-color 0.2s ease-in-out,
        width 0.2s ease;
    &:hover {
        cursor: pointer;
        background-color: #5898ff;
    }
}
.ru {
    width: 80px;
}
.en {
    width: 101px;
}

.itemSpan {
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    white-space: nowrap;
    padding: 1px 0 0 4px;
}
