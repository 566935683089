.transactions {
    width: 100%;
    max-height: 100vh;
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    &Container {
        width: 100%;
        background-color: light-dark(var(--color-light), var(--color-dark));
        border-radius: 10px;
        padding: 20px 0 0 0;
        overflow: hidden;
    }
    &Wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 20px 30px 20px;
    }
    &TitleWrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &Title {
        font-weight: 400;
        font-size: 16px;
        color: light-dark(var(--text-color-light), var(--text-color-dark));
    }
    &Subtitle {
        font-weight: 400;
        font-size: 14px;
        color: #8b8b8b;
    }
    &Line {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        min-height: 32px;
        padding: 4px 50px 4px 29px;
        background-color: light-dark(var(--info-background-color-light), var(--info-background-color-dark));
        border: 1px solid light-dark(var(--info-border-color-light), var(--info-border-color-dark));
        gap: 30px;
    }
    &Text {
        display: inline-flex;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        color: #8b8b8b;
    }
    &Search {
        width: max-content;
        padding: 4px 4px 4px 12px;
        border: 1px solid #1e1e1e;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin: 0 auto 13px auto;
    }
    &Input {
        width: 350px;
        min-width: 0;
        background-color: initial;
        font-weight: 400;
        font-size: 14px;
    }
    &Item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: 10px;
        height: max-content;
    }
    &Button {
        display: flex;
        align-items: center;
        background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        border-radius: 100px;
        font-weight: 400;
        font-size: 12px;
        padding: 4px 6px;
        gap: 4px;
        transition: transform 0.1s ease;
        &:active {
            transform: scale(0.95);
        }
    }
    &Preferences {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin: 0 0 30px 0;
        gap: 8px;
    }
    &PreferencesButton {
        font-weight: 400;
        font-size: 12px;
        padding: 4px 10px;
        border-radius: 12px;
        border: 1px dashed light-dark(var(--border-color-light), var(--border-color-dark));
    }
    &FilterDropdown {
        position: absolute;
        width: 170px;
        max-height: 188px;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        border-radius: 10px;
        background-color: light-dark(var(--color-light), var(--color-dark));
        margin: 33px 0 0 0;
        overflow: hidden;
        z-index: 1;
    }
    &FilterSearch {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 8px 13px;
        border-bottom: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        gap: 10px;
        align-items: center;
    }
    &FilterInput {
        max-width: 120px;
        font-weight: 400;
        font-size: 14px;
        background-color: initial;
    }
    &FilterUl {
        margin: 5px 0;
        max-height: 112px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: light-dark(var(--second-color-light), var(--second-color-dark));
            border-radius: 15px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #282c38;
            border-radius: 15px;
        }
        &::-webkit-scrollbar-corner {
            background-color: light-dark(var(--color-light), var(--color-dark));
        }
    }
    &FilterItem {
        cursor: pointer;
        display: inline-flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        padding: 7px 13px;
        &:hover {
            background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        }
    }
    &FilterArrow {
        transform: rotate(-90deg);
    }
    &FilterTag {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        gap: 5px;
        padding: 0 0 0 6px;
        font-weight: 400;
        font-size: 12px;
    }
    &DialogFilterCheckbox {
        appearance: none;
        -webkit-appearance: none;
        width: 11px;
        height: 11px;
        border: 1px solid #dbdbdb;
        border-radius: 2px;
        position: relative;
        cursor: pointer;
        transition: background-color 0.2s;
        &:checked {
            background-color: #0062ff;
        }
        &:disabled {
            background-color: #6fa6ff;
        }
    }
    &Cross {
        max-width: 12px;
        max-height: 12px;
    }
    &CrossButton {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 20px;
        border-left: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
    }
    &BackButton {
        display: flex;
        align-items: center;
        padding: 10px 13px;
        width: 100%;
        gap: 10px;
        border-top: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        font-weight: 400;
        font-size: 12px;
    }
    &BackArrow {
        transform: rotate(90deg);
    }
    &Dialog {
        margin: auto;
        width: max-content;
        height: max-content;
        border-radius: 10px;
        &::backdrop {
            background-color: light-dark(var(--backdrop-color-light), var(--backdrop-color-dark));
            backdrop-filter: blur(5px);
        }
        &Header {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            padding: 40px 40px 0 40px;
            font-weight: 400;
            font-size: 28px;
        }
        &Wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            padding: 54px 40px 18px;
            border-bottom: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
            justify-content: space-between;
            font-weight: 400;
            font-size: 18px;
            line-height: 33px;
        }
        &Sub {
            display: inline-flex;
            align-items: center;
            line-height: 21px;
            gap: 16px;
            cursor: pointer;
            font-weight: 400;
            font-size: 18px;
            padding: 6px 5px;
            border-radius: 5px;
            transition: background-color 0.2s ease;
            &:hover {
                background-color: light-dark(var(--second-color-light), var(--second-color-dark));
            }
        }
        &Ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 25px 40px 50px;
        }
        &Label {
            width: 100%;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 6px 5px;
            border-radius: 5px;
            height: 30px;
            transition: background-color 0.2s ease;
            font-weight: 400;
            font-size: 16px;
            &:hover {
                background-color: light-dark(var(--second-color-light), var(--second-color-dark));
            }
        }
        &Buttons {
            display: flex;
            justify-content: space-between;
            padding: 0 40px 40px 40px;
            gap: 23px;
        }
        &Close,
        &Save {
            padding: 12px 95.5px;
            border-radius: 10px;
            font-weight: 400;
            font-size: 12px;
        }
        &Close {
            background-color: initial;
            color: #0062ff;
            border: 1px solid #0062ff;
            transition:
                color 0.2s ease,
                background-color 0.2s ease;
            &:hover {
                color: #fff;
                background-color: #4089ff;
                border-color: #4089ff;
            }
        }
        &Save {
            background-color: #0062ff;
            color: #fff;
            transition: background-color 0.2s ease;
            &:hover {
                background-color: #4089ff;
            }
        }
        &Checkbox {
            appearance: none;
            -webkit-appearance: none;
            width: 18px;
            height: 18px;
            border: 2px solid #dbdbdb;
            border-radius: 5px;
            position: relative;
            cursor: pointer;
            transition: background-color 0.2s;
            &:checked {
                background-color: #0062ff;
            }
            &:disabled {
                background-color: #6fa6ff;
            }
        }
    }
}
.list {
    min-height: 172px;
    max-height: calc(100vh - 290px);
    overflow-y: auto;
    direction: ltr;
    direction: rtl;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: light-dark(var(--color-light), var(--color-dark));
        border-radius: 15px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #282c38;
        border-radius: 15px;
    }
    &::-webkit-scrollbar-corner {
        background-color: light-dark(var(--color-light), var(--color-dark));
    }
    &Item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 0 50px 0 25px;
        width: 100%;
        height: 48px;
        border-bottom: 1px solid light-dark(var(--info-border-color-light), var(--info-border-color-dark));
        direction: ltr;
        gap: 30px;
    }

    &Wrapper {
        font-weight: 400;
        font-size: 14px;
        color: #8b8b8b;
        display: flex;
        flex-direction: row;
        justify-content: center;
        white-space: nowrap;
        height: 14px;
    }
    &StatusTrue {
        font-weight: 700;
        font-size: 14px;
        position: absolute;
        padding: 2px 8px;
        border-radius: 4px;
        top: -2px;
        text-transform: uppercase;
        color: light-dark(var(--true-color-light), var(--true-color-dark));
        background-color: light-dark(var(--true-text-color-light), var(--true-text-color-dark));
    }
    &StatusFalse {
        font-weight: 700;
        font-size: 14px;
        position: absolute;
        padding: 2px 8px;
        padding: 2px 8px;
        border-radius: 4px;
        top: -2px;
        color: #ffcc85;
        text-transform: uppercase;
        background-color: var(--false-color-dark);
    }
    &Id {
        width: 47px;
    }
    &Hash {
        width: 190px;
    }
    &Api {
        width: 100px;
        height: max-content;
        text-wrap: wrap;
        word-break: break-all;
        text-align: left;
    }
    &Label {
        width: 100px;
        height: max-content;
        text-wrap: wrap;
        word-break: break-all;
        text-align: left;
    }
    &Token {
        width: 100px;
    }
    &Amount {
        width: 100px;
    }
    &Paid {
        position: relative;
        width: 140px;
    }
    &Sent {
        position: relative;
        width: 140px;
    }
    &Time {
        width: 115px;
    }
    &NoTransactions {
        width: 100%;
        height: 172px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        &Title {
            font-weight: 400;
            font-size: 16px;
            text-align: center;
        }
        &Subtitle {
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            max-width: 200px;
            line-height: 14px;
        }
    }
}

.dialog {
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    background-color: light-dark(var(--second-color-light), var(--second-color-dark));
    &::backdrop {
        background-color: light-dark(var(--backdrop-color-light), var(--backdrop-color-dark));
        backdrop-filter: blur(5px);
    }
    &Container {
        display: flex;
        flex-direction: column;
        padding: 40px;
        gap: 50px;
        width: 550px;
    }
    &Header {
        display: flex;
        justify-content: space-between;
    }
    &Title {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
    }
    &Content {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    &Margin {
        margin: 30px 0 0 0;
    }
    &DropContent {
        display: flex;
        flex-direction: column;
        gap: 30px;
        overflow: hidden;
    }
    &DropButton {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Label {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &:last-child {
            pointer-events: none;
        }
    }
    &Span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Input {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding: 12px 14px;
        border: 1px solid #1e1e1e;
        background-color: initial;
        border-radius: 10px;
    }

    &Small {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }

    &Button {
        padding: 11px 0;
        border-radius: 10px;
        background-color: #0062ff;
        transition: transform 0.1s ease;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #fff;
        &:active {
            transform: scale(0.95);
        }
    }
    &CheckboxesContainer {
        display: flex;
        flex-direction: row;
        gap: 100px;
    }
    &CheckboxGroup {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
.calendarContainer {
    background-color: #0d0d0d;
    border: 1px solid #1d1d1d;
    overflow: hidden;
    border-radius: 8px;
    padding: 20px;
    position: absolute;
    width: max-content;
    height: max-content;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    margin: 37px 4px 0 0;
}

.applyButton {
    background-color: #4caf50;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}
.arrow {
    transition: transform 0.3s ease-in-out;
    &Rotated {
        transform: rotate(180deg);
    }
}
.applyButton:hover {
    background-color: #388e3c;
}
