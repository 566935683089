.mainProfile {
    @media (max-width: 768px) {
        max-width: 100%;
        max-height: none;
        gap: 12px;

        &Wrapper {
            width: 100%;
            height: auto;
            padding: 15px;
            gap: 20px;
        }

        &Block {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }

        &EmailBlock {
            width: 100%;
            padding: 15px;
            gap: 15px;
        }
        &Button {
            max-width: 310px;
        }
        &Title:last-child {
            display: flex;
            align-items: center;
        }

        &Item {
            width: 100%;
            padding: 15px;
            gap: 15px;
        }
    }

    @media (max-width: 480px) {
        &Wrapper {
            padding: 12px;
            gap: 15px;
        }

        &EmailBlock,
        &Item {
            padding: 12px;
            gap: 12px;
        }
    }
}

.dialog {
    @media (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        width: 100vw;
        height: 100vh;
        max-width: none;
        max-height: none;
        border: none;
        border-radius: 0;

        &FormWrapper {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
        }
        &Container {
            width: 100%;
            height: 500px;
            padding: 20px;
            gap: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &Content {
            gap: 20px;
        }

        &Label {
            gap: 10px;
        }

        &InputPass {
            min-height: 45px;
            max-height: 45px;
            padding: 12px 15px;
        }

        &Eye {
            min-height: 45px;
            max-height: 45px;
            padding: 0 15px 0 0;
        }
    }

    @media (max-width: 480px) {
        &Container {
            padding: 15px;
            gap: 15px;
        }

        &Content {
            gap: 15px;
        }

        &Label {
            gap: 8px;
        }

        &InputPass {
            min-height: 40px;
            max-height: 40px;
            padding: 10px 12px;
        }

        &Eye {
            min-height: 40px;
            max-height: 40px;
            padding: 0 12px 0 0;
        }
    }
}

.tooltip {
    @media (max-width: 768px) {
        padding: 4px 8px;
        top: -24px;

        &::after {
            top: 18px;
        }
    }

    @media (max-width: 480px) {
        padding: 3px 6px;
        top: -22px;

        &::after {
            top: 16px;
        }
    }
}
