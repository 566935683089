@media (max-width: 1060px) {
    .searchForm {
        margin: 0;
        width: 300px;
    }
}
@media (max-width: 980px) {
    .titleWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }
    .burger {
        display: flex;
        position: relative;
        top: -1px;
        width: 16px;
        height: 8px;
        cursor: pointer;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        span {
            display: flex;
            position: absolute;
            top: 50%;
            width: 16px;
            height: 2px;
            border-radius: 10px;
            background-color: light-dark(var(--text-color-light), var(--text-color-dark));
            transition: all 0.3s ease !important;
            &:first-child {
                top: 0;
            }
            &:last-child {
                top: 100%;
                left: 0;
                width: 12px;
            }
        }
    }
    .header {
        padding: 20px 25px;
    }
}
@media (max-width: 770px) {
    .searchForm {
        display: none;
    }
}
@media (max-width: 576px) {
    .header {
        &Sum {
            display: none;
        }
    }
}
@media (max-width: 430px) {
    .itemStart {
        max-width: 26px;
        min-width: 26px;
        min-height: 26px;
        border-radius: 50%;
    }
    .itemSpan {
        display: none;
    }
}
