.chart {
    &Item {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        width: 100%;
        min-height: 310px;
        padding: 20px;
        background-color: light-dark(var(--color-light), var(--color-dark));
        border-radius: 10px;
    }
    &Wrapper {
        align-self: flex-start;
        justify-self: start;
        margin: 0 0 32px 0;
    }
    &Title {
        font-weight: 400;
        font-size: 16px;
        color: light-dark(var(--text-color-light), var(--text-color-dark));
        margin: 0 0 8px 0;
    }
    &Subtitle {
        font-weight: 400;
        font-size: 14px;
        color: #8b8b8b;
    }
    &Table {
        display: flex;
        justify-content: center;
        gap: 25px;
    }
    &TableSecond {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 150px;
        row-gap: 8px;
        column-gap: 60px;
    }
    &Text {
        font-weight: 400;
        font-size: 12px;
        color: light-dark(var(--text-color-light), var(--text-color-dark));
    }
    &Box {
        width: 11px;
        height: 11px;
        border-radius: 4px;
        margin: 0 8px 0 0;
        display: inline-block;
    }
}
.graph {
    display: flex;
    justify-content: center;
    width: 190px !important;
    height: 190px !important;
    &Container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
