.graph {
    padding: 0 32px 20px;
    &Container {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 210px;
        padding: 20px 20px 0 20px;
        background-color: light-dark(var(--color-light), var(--color-dark));
        border-radius: 10px;
    }
    &Wrapper {
        align-self: flex-start;
        justify-self: start;
        margin: 0 0 5px 0;
    }
    &Title {
        font-weight: 400;
        font-size: 16px;
        color: light-dark(var(--text-color-light), var(--text-color-dark));
        margin: 0 0 8px 0;
    }
    &Subtitle {
        font-weight: 400;
        font-size: 14px;
        color: #8b8b8b;
    }
}
