@media (max-width: 1250px) {
    .chart {
        padding: 18px 25px 20px;
    }
}
@media (max-width: 780px) {
    .chart {
        flex-direction: column;
    }
}
