.main {
    display: flex;
    width: max-content;
    gap: 16px;
    align-items: center;
    &Wrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 20px;
        background-color: #0d0d0d;
        border-radius: 10px;
        max-width: 360px;
    }
    &Title,
    &Span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        width: max-content;
    }
    &Paragraph {
        display: flex;
        flex-direction: column;

        gap: 20px;
    }
    &Text {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }
    &Users {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        width: 370px;
        max-height: 310px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: initial;
            border-radius: 15px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #282c38;
            border-radius: 15px;
        }
        &::-webkit-scrollbar-corner {
            background-color: light-dark(var(--color-light), var(--color-dark));
        }
    }
    &Button {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        padding: 10px;
        width: 100%;
        border-radius: 8px;
        background-color: #0062ff;
        transition: transform 0.1s ease;
        &:active {
            transform: scale(0.95);
        }
    }
    &Li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 12px 25px;
        border-radius: 10px;
        background-color: light-dark(var(--color-light), var(--color-dark));
    }
    &UserBlock {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }
    &Image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
    &User {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3px;
    }
    &Email {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Role {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        padding: 3px 10px;
        border-radius: 4px;
    }
    &DeleteButton {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        cursor: pointer;
        transition:
            transform 0.1s ease,
            background-color 0.2s ease;
        &:active {
            transform: scale(0.9);
        }
        &:hover,
        &:hover > .mainDeleteSvg {
            fill: light-dark(var(--negative-text-color-light), var(--negative-text-color-dark));
            background-color: light-dark(var(--negative-background-color-light), var(--negative-background-color-dark));
        }
    }
    &DeleteSvg {
        width: 15px;
        height: 15px;
    }
}
.dialog {
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    background-color: light-dark(var(--second-color-light), var(--second-color-dark));
    &::backdrop {
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(5px);
    }
    &Container {
        display: flex;
        flex-direction: column;
        padding: 40px;
        gap: 50px;
        width: 550px;
    }
    &Header {
        display: flex;
        justify-content: space-between;
    }
    &Title {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
    }
    &Content {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    &Label {
        display: flex;
        flex-direction: column;
        gap: 12px;
        &:last-child {
            pointer-events: none;
        }
    }
    &Span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Input {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding: 12px 14px;
        border: 1px solid #1e1e1e;
        background-color: initial;
        border-radius: 10px;
    }
    &Small {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }

    &Button {
        padding: 11px 0;
        border-radius: 10px;
        background-color: #0062ff;
        transition: transform 0.1s ease;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #fff;
        &:active {
            transform: scale(0.95);
        }
    }
    &CheckboxesContainer {
        display: flex;
        flex-direction: row;
        gap: 100px;
    }
    &CheckboxGroup {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}

.checkbox {
    &Label {
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        border-radius: 6px;
        margin-right: 11px;
        overflow: hidden;
        transition: all 0.1s ease;
        display: inline-block;
        pointer-events: auto;
        &:active {
            transform: scale(0.9);
        }
        & span {
            float: left;
            vertical-align: middle;
            transform: translate3d(0, 0, 0);
        }

        & span:first-child {
            position: relative;
            width: 18px;
            height: 18px;
            border-radius: 5px;
            transform: scale(1);
            border: 2px solid #cccfdb;
            transition: all 0.2s ease;
        }

        & span:first-child svg {
            position: absolute;
            top: 2px;
            left: 1px;
            fill: none;
            stroke: #fff;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 16px;
            stroke-dashoffset: 16px;
            transition: all 0.3s ease;
            transition-delay: 0.1s;
            transform: translate3d(0, 0, 0);
        }

        & span:last-child {
            padding-left: 8px;
            line-height: 18px;
        }
    }

    &Checkbox {
        width: 0px;
        height: 0px;
        position: relative;
        opacity: 0;
    }

    &Checkbox:checked + &Label span:first-child {
        background: #07f;
        border-color: #07f;
    }

    &Checkbox:checked + &Label span:first-child svg {
        stroke-dashoffset: 0;
    }
    &Checkbox:disabled + &Label {
        cursor: default;
        transform: scale(0.8);
    }
    &Wrapper {
        display: flex;
        align-items: center;
        box-sizing: border-box;
    }
    &Svg {
        position: absolute;
        width: 0;
        height: 0;
        pointer-events: none;
        user-select: none;
    }
}

.roleAdministrator {
    color: #ff3434;
    background-color: #ffe0e0;
}
.roleDeveloper {
    color: #0062ff;
    background-color: #e7f0ff;
}
.roleFinancier {
    color: #25ca00;
    background-color: #f0f9f3;
}
.roleSupportSpecialist {
    color: #5d37ba;
    background-color: #f4efff;
}
