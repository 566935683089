.Authorization {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100vh;
    background-color: #0a0a0a;

    &Video {
        height: 100%;
        width: 50%;
        padding: 14px;
        border-radius: 25px;
        overflow: hidden;
        object-fit: cover;
    }
    &Container {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 14px 0 0 0;
    }
    &Wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;
        gap: 12px;
        width: 510px;
    }
    &Item {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #272727;
        min-width: 26px;
        min-height: 26px;
        border-radius: 50%;
        transition: background-color 0.2s ease-in-out;
        &:hover {
            cursor: pointer;
            background-color: #272727;
        }
    }
}
