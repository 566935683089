.faq {
    display: flex;
    flex-direction: column;
    margin: 3px 32px 0 32px;
    border-radius: 10px;
    padding: 20px;
    background-color: light-dark(var(--color-light), var(--color-dark));

    &Container {
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
    }

    &Title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 40px;
    }

    &Content {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    &Section {
        &Title {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 24px;
        }
    }

    &List {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &Item {
        border-bottom: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        overflow: hidden;
    }

    &Question {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        background: none;
        border: none;
        text-align: left;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    &Icon {
        font-size: 24px;
        font-weight: 300;
        transition: transform 0.3s ease;
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;

        &.active {
            transform: rotate(180deg);
        }
    }

    &Answer {
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease;
        p {
            margin: 0;
            padding: 0 24px 24px;
            font-size: 16px;
            line-height: 1.6;
            color: light-dark(var(--text-color-light), var(--text-color-dark));
        }

        &.active {
            max-height: 500px;
        }
    }
}
