.label {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.span {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}
.input {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 17px 14px;
    border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
    border-radius: 10px;
    background-color: initial;
}
