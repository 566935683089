@media (max-width: 1600px) {
    .balance {
        &Block {
            overflow-x: scroll;
            &::-webkit-scrollbar {
                height: 5px;
            }
            &::-webkit-scrollbar-track {
                background-color: light-dark(var(--color-light), var(--color-dark));
                border-radius: 15px;
                width: 100%;
                height: 100%;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #282c38;
                border-radius: 15px;
            }
        }
    }
    .balanceLine,
    .list {
        width: 100%;
        min-width: max-content;
    }
}
@media (max-width: 1250px) {
    .balance {
        padding: 0 25px;
        &Info {
            flex-direction: column;
        }
    }
}
@media (max-width: 768px) {
    .balance {
        &Dialog {
            &Header {
                padding: 0;
            }
            &Ul {
                padding: 0;
            }
            &Wrapper {
                gap: 0;
                justify-content: space-between;
            }
        }
        &DialogButtons,
        &DialogWrapper {
            padding: 0;
        }
        &DialogClose,
        &DialogSave {
            width: 100%;
        }
        &Search {
            flex-direction: column;
            padding: 4px 12px;
        }
    }
    .calendarContainer {
        margin: 63px auto 0 auto;
        right: -35px;
    }
    .dialog {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        width: 100vw;
        height: 100vh;
        max-width: none;
        max-height: none;
        border: none;
        border-radius: 0;

        &Block {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
        &Container {
            width: 100%;
            height: max-content;
            padding: 20px;
            gap: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &Content {
            gap: 20px;
        }

        &Label {
            gap: 10px;
        }

        &Input {
            min-height: 45px;
            max-height: 45px;
            padding: 12px 15px;
        }
    }
}
@media (max-width: 500px) {
    .balance {
        &Info {
            &Container {
                max-height: 100%;
            }
        }
    }
}
