.subscriptions {
    @media (max-width: 1024px) {
        &WrapperMain {
            flex-wrap: wrap;
            justify-content: center;
        }

        &Item {
            width: calc(50% - 8px);
            min-width: 300px;
            height: auto;
            min-height: 335px;
        }
    }

    @media (max-width: 768px) {
        gap: 20px;

        &WrapperTitle {
            flex-wrap: wrap;
            gap: 10px;
        }
        &Button {
            width: 100%;
        }
        &WrapperMain {
            gap: 12px;
        }

        &Item {
            width: 100%;
            padding: 30px 20px;
            min-width: unset;
        }

        &ItemActive {
            padding: 29px 19px;
        }

        &Info {
            max-width: 100%;
        }

        &WrapperPayment {
            flex-wrap: wrap;
            gap: 12px;
        }

        &PaymentItem {
            width: 100%;
            padding: 15px 20px;
        }

        &PaymentItemActive {
            padding: 14px 19px;
        }

        &Svg {
            width: 40px;
            height: 35px;
        }
    }

    @media (max-width: 480px) {
        gap: 15px;

        &WrapperTitle {
            gap: 8px;
        }

        &WrapperMain {
            gap: 10px;
        }

        &Item {
            padding: 25px 15px;
        }

        &ItemActive {
            padding: 24px 14px;
        }

        &PaymentItem {
            padding: 12px 15px;
        }

        &PaymentItemActive {
            padding: 11px 14px;
        }

        &Svg {
            width: 35px;
            height: 30px;
        }
    }
}
