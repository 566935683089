.main {
    @media (max-width: 1024px) {
        width: 100%;
        max-width: 100%;
        padding: 15px;
        gap: 20px;

        &Container {
            gap: 40px;
            width: 100%;
        }

        &Checkboxes {
            max-width: 100%;
            column-gap: 20px;
        }
    }

    @media (max-width: 768px) {
        padding: 12px;
        gap: 15px;
        width: 100%;
        max-width: 100%;

        &Container {
            flex-direction: column;
            gap: 30px;
        }

        &Wrapper {
            width: 100%;
        }

        &Checkboxes {
            row-gap: 8px;
            column-gap: 15px;
        }

        &Block {
            gap: 10px;
        }
    }

    @media (max-width: 480px) {
        padding: 10px;
        gap: 12px;

        &Container {
            gap: 20px;
        }

        &Checkboxes {
            row-gap: 6px;
            column-gap: 10px;
        }

        &Block {
            gap: 8px;
        }

        &Switch {
            width: 32px;
            height: 18px;
        }

        &Slider {
            &:before {
                height: 14px;
                width: 14px;
            }
        }

        &Input:checked + &Slider:before {
            transform: translateX(14px);
        }
    }
}

.checkbox {
    @media (max-width: 480px) {
        &Label {
            & span:first-child {
                width: 16px;
                height: 16px;
            }

            & span:last-child {
                padding-left: 6px;
            }
        }
    }
}
