.main {
    display: flex;
    flex-direction: column;
    max-width: 740px;
    height: 310px;
    overflow: hidden;
    padding: 20px 0 0 0;
    background-color: light-dark(var(--color-light), var(--color-dark));
    border-radius: 10px;
    gap: 33px;
    &Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
    }
    &Title {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Button {
        padding: 4px 10px;
        border-radius: 100px;
        border: 1px solid #0062ff;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        transition: transform 0.1s ease;
        &:active {
            transform: scale(0.95);
        }
    }
    &Wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
            height: 5px;
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: light-dark(var(--color-light), var(--color-dark));
            border-radius: 15px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #282c38;
            border-radius: 15px;
        }
        &::-webkit-scrollbar-corner {
            background-color: light-dark(var(--color-light), var(--color-dark));
        }
    }
    &Line {
        position: absolute;
        width: 740px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
        padding: 8px 8px;
        background-color: light-dark(var(--info-background-color-light), var(--info-background-color-dark));
        border: 1px solid light-dark(var(--info-border-color-light), var(--info-border-color-dark));
    }
    &List {
        display: flex;
        flex-direction: column;
        padding: 4px 8px;
        margin: 40px 0 0 0;
        width: 740px;
        height: 100%;
        max-height: 185px;
        overflow-y: auto;
        gap: 3px;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: light-dark(var(--color-light), var(--color-dark));
            border-radius: 15px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #282c38;
            border-radius: 15px;
        }
        &::-webkit-scrollbar-corner {
            background-color: light-dark(var(--color-light), var(--color-dark));
        }
    }
    &Text {
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #8b8b8b;
    }
    &Item {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
        padding: 8px 0;
        cursor: pointer;
        transition: background-color 0.2s ease;
        border-radius: 10px;
        &:hover {
            background-color: light-dark(var(--second-color-light), var(--second-color-dark));
        }
    }
    &WithdrawEnabled {
        background-color: #0062ff;
        &:hover,
        &:hover .mainDelete {
            background-color: #003c9c;
        }
    }
    &Span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: auto;
    }
    &Addres {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        width: 115px;
    }
    &Address {
        position: relative;
        display: inline-flex;
        align-items: center;
        gap: 4px;
        transition:
            transform 0.1s ease,
            color 0.2s ease,
            stroke 0.2s ease;
        &:hover .mainCopySvg,
        &:hover {
            color: #448cff;
            stroke: #448cff;
        }
        &:active {
            transform: scale(0.95);
        }
    }
    &CopySvg {
        width: 15px;
        height: 15px;
        transition: stroke 0.2s ease;
    }
    &Currency {
        display: inline-flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 60px;
    }
    &Address {
        display: inline-flex;
        justify-content: center;
        width: 115px;
    }
    &Label {
        display: inline-flex;
        justify-content: center;
        width: 170px;
        min-width: 70px;
        text-align: center;
    }
    &Void {
        width: 18px;
        height: 18px;
    }
    &TrashSvg {
        fill: #8b8b8b;
        transition:
            fill 0.2s ease,
            background-color 0.2s ease,
            transform 0.1s ease;
    }
    &Delete {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        border-radius: 5px;
        cursor: pointer;
        transition:
            background-color 0.2s ease,
            transform 0.1s ease;
        &:active {
            transform: scale(0.9);
        }
        &:hover,
        &:hover > .mainTrashSvg {
            fill: light-dark(var(--negative-text-color-light), var(--negative-text-color-dark)) !important;
            background-color: light-dark(
                var(--negative-background-color-light),
                var(--negative-background-color-dark)
            ) !important;
        }
    }
    &Block {
        display: flex;
        align-items: center;
        gap: 12px;
    }
    &Switch {
        position: relative;
        display: inline-block;
        width: 36px;
        height: 20px;
    }
    &Slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #d2d5da;
        transition: 0.4s;
        border-radius: 34px;
        &:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            border-radius: 50%;
            left: 2px;
            bottom: 2px;
            background-color: #fff;
            transition: 0.4s;
        }
    }
    &Input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    &Input:checked + &Slider {
        background-color: #2663ea;
    }
    &Input:checked + &Slider:before {
        transform: translateX(16px);
    }
    &ItemText {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.dialog {
    margin: auto;
    border-radius: 10px;
    overflow: hidden;
    &::backdrop {
        background-color: light-dark(var(--backdrop-color-light), var(--backdrop-color-dark));
        backdrop-filter: blur(5px);
    }
    &Container {
        display: flex;
        flex-direction: column;
        padding: 40px;
        gap: 50px;
        width: 550px;
    }
    &Header {
        display: flex;
        justify-content: space-between;
    }
    &Title {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
    }
    &Content {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    &Label {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    &Span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Input {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding: 17px 14px;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        border-radius: 10px;
        background-color: initial;
    }
    &Button {
        padding: 11px 0;
        border-radius: 10px;
        background-color: #0062ff;
        transition: transform 0.1s ease;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #fff;
        &:active {
            transform: scale(0.95);
        }
    }
}
.tooltip {
    position: absolute;
    color: light-dark(var(--text-color-light), var(--text-color-dark));
    background-color: light-dark(var(--color-light), var(--color-dark));
    border: 1px solid #0062ff;
    width: max-content;
    padding: 3px 6px;
    border-radius: 5px;
    top: -10px;
    left: -30%;
    z-index: 2;
    transform: translateX(-50%);
    font-size: 12px;
}
.currencyNetwork {
    font-size: 10px;
    color: #8b8b8b;
    display: block;
    margin-top: 2px;
}
