.mainSafety {
    display: flex;
    flex-wrap: wrap-reverse;
    max-width: 835px;
    gap: 16px;
    &Title {
        display: inline-flex;
        gap: 7px;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Disabled {
        padding: 3px 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: light-dark(var(--negative-text-color-light), var(--negative-text-color-dark));
        background-color: light-dark(var(--negative-background-color-light), var(--negative-background-color-dark));
        border-radius: 8px;
    }
    &Enabled {
        padding: 3px 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: light-dark(var(--positive-text-color-light), var(--positive-text-color-dark));
        background-color: light-dark(var(--positive-background-color-light), var(--positive-background-color-dark));
    }
    &Wrapper {
        display: flex;
        flex-direction: column;
        padding: 20px;
        width: 420px;
        height: max-content;
        max-height: 505px;
        border-radius: 10px;
        background-color: light-dark(var(--color-light), var(--color-dark));
        gap: 42px;
    }
    &History {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        overflow: hidden;
        overflow-y: scroll;
        gap: 10px;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: initial;
            border-radius: 15px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #282c38;
            border-radius: 15px;
        }
        &Container {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            padding: 3px 6px;

            border-radius: 5px;
            &:nth-child(odd) {
                background-color: light-dark(var(--second-color-light), var(--second-color-dark));
            }
        }
        &Item {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 200px;
        }
        &Text {
            display: inline-flex;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            gap: 3px;
        }
        &Span {
            font-weight: 700;
            color: #959595;
            font-size: 14px;
            line-height: 16px;
        }
        &Svg {
            min-width: 14px;
            max-width: 14px;
            min-height: 14px;
            max-height: 14px;
            fill: #959595;
        }
    }
    &Container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 360px;
        gap: 20px;
    }
    &Item {
        display: flex;
        flex-direction: column;
        padding: 20px;
        gap: 25px;
        width: 100%;
        max-width: 360px;
        height: 120px;
        background-color: light-dark(var(--color-light), var(--color-dark));
        border-radius: 10px;
    }
    &Button {
        padding: 10px 0;
        width: 100%;
        color: #fff;
        border-radius: 8px;
        background-color: #0062ff;
        transition:
            background-color 0.2s ease,
            transform 0.1s ease;
        &:hover {
            background-color: #003c9c;
        }
        &:active {
            transform: scale(0.95);
        }
    }
}
.dialog {
    margin: auto;
    border-radius: 10px;
    overflow: hidden;

    &::backdrop {
        background-color: light-dark(var(--backdrop-color-light), var(--backdrop-color-dark));
        backdrop-filter: blur(5px);
    }
    &Container {
        display: flex;
        flex-direction: column;
        padding: 40px;
        gap: 50px;
        width: 550px;
    }
    &Header {
        display: flex;
        justify-content: space-between;
    }
    &Title {
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        max-width: 265px;
    }
    &Content {
        display: flex;
        flex-direction: column;
        gap: 30px;
        align-items: center;
    }
    &Label {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    &Span {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
    }
    &Input {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding: 17px 14px;
        border: 1px solid light-dark(var(--border-color-light), var(--border-color-dark));
        border-radius: 10px;
    }
    &Button {
        padding: 11px 0;
        border-radius: 10px;
        background-color: #0062ff;
        transition: transform 0.1s ease;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #fff;
        &:active {
            transform: scale(0.95);
        }
    }
    &Qr {
        width: 250px;
        height: 250px;
        border-radius: 24px;
    }
    &Information {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    &InformationText {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        color: #3c3c3c;
    }
    &InformationSvg {
        min-width: 14px;
        max-width: 14px;
    }
}
